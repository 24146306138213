//src/pages/pre-built/user-manage/MedicalPartner.js
import React, { useState, useEffect, useContext } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { findUpper } from "../../../utils/Utils";
import { userData, filterRole, filterStatus } from "./UserData";
import moment from "moment";
import swal from "sweetalert";

import {
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  DropdownItem,
  Form,
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  UserAvatar,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  Button,
  RSelect,
  TooltipComponent,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { UserContext } from "./UserContext";
import { bulkActionOptions } from "../../../utils/Utils";
import axios from "axios";
import { API_BASE_URL } from "../../../utils/Utils";

const MedicalPartner = () => {
  const { contextData } = useContext(UserContext);
  const [data, setData] = contextData;

  const [sm, updateSm] = useState(false);
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [modal, setModal] = useState({ edit: false, add: false });
  const [editId, setEditedId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [errorVal, setError] = useState("");
  const [partnerList, setPartnerList] = useState([]);
  const [token] = useState(localStorage.getItem("accessToken"));
  const [change, setChange] = useState(false);
  
  const [filters, setFilters] = useState({
    status: "",
    country: "",
    date: ""
  });
  const api = axios.create({
    baseURL: API_BASE_URL,
    headers: {
      'Content-Type': 'application/json'
    }
  });

  const [formData, setFormData] = useState({
    _id: "",
    organization_name: "",
    contact_person_name: "",
    contact_person_email: "",
    contact_person_phone: "",
    contact_person_country_code: "",
    contact_person_address: "",
    contact_person_city: "",
    contact_person_country: "",
    client_manager: "",
    business_website: "",
    business_registration: "",
    organization_logo: "",
    default_logo: "",
    isIncludeHotel: "no",
    isIncludeAccommodation: "no",
    isIncludeTranslator: "no",
    remarks: "",
    user_type: "",
    status: "",
    createdAt: "",
  });

  // Load initial data
  useEffect(() => {
    if (!token) return;
  
    setLoading(true);
    axios
      .get(`${API_BASE_URL}/api/v1/contact-partner?user_type=medical_partner`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if (response.data?.data) {
          const cleanData = response.data.data.filter(item => item && item.organization_name);
          setPartnerList(cleanData);
          setData(cleanData);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error loading data:", error);
        setLoading(false);
      });
  }, [token]);

  // Effect for search
  useEffect(() => {
    if (!data) return;

    if (onSearchText !== "") {
      const filteredObject = data.filter((item) => {
        const searchFields = [
          item?.organization_name,
          item?.contact_person_name,
          item?.contact_person_email,
          item?.contact_person_phone,
          item?.contact_person_address,
          item?.contact_person_city,
          item?.contact_person_country,
          item?.client_manager
        ];

        return searchFields.some(field => 
          field?.toLowerCase().includes(onSearchText.toLowerCase())
        );
      });
      setPartnerList(filteredObject);
    } else {
      setPartnerList(data);
    }
  }, [onSearchText, data]);

  const statusOptions = [
    { value: "Active", label: "Active" },
    { value: "Pending", label: "Pending" },
    { value: "Suspend", label: "Suspended" }
  ];

  const countryOptions = React.useMemo(() => {
    if (!data) return [];
    return [...new Set(data.map(item => item?.contact_person_country))]
      .filter(Boolean)
      .map(country => ({
        value: country,
        label: country
      }));
  }, [data]);

  // Function to reset form
  const resetForm = () => {
    setFormData({
      _id: "",
      organization_name: "",
      contact_person_name: "",
      contact_person_email: "",
      contact_person_phone: "",
      contact_person_country_code: "",
      contact_person_address: "",
      contact_person_city: "",
      contact_person_country: "",
      client_manager: "",
      business_website: "",
      business_registration: "",
      organization_logo: "",
      default_logo: "",
      isIncludeHotel: "no",
      isIncludeAccommodation: "no",
      isIncludeTranslator: "no",
      remarks: "",
      user_type: "",
      status: "",
      createdAt: "",
    });
  };

  const onFilterChange = (e, type) => {
    if (type) {
      const value = type === 'date' ? e.target.value : e?.value || '';
      setFilters(prev => ({
        ...prev,
        [type]: value
      }));
      setChange(true);
    } else {
      setSearchText(e.target.value);
    }
  };

  const applyFilter = () => {
    if (!data) return;
    let filteredData = [...data];
  
    Object.entries(filters).forEach(([key, value]) => {
      if (!value) return;
      
      switch(key) {
        case 'status':
          filteredData = filteredData.filter(item => item.status === value);
          break;
        case 'country':
          filteredData = filteredData.filter(item => item.contact_person_country === value);
          break;
        case 'date':
          filteredData = filteredData.filter(item => 
            moment(item.createdAt).format('YYYY-MM-DD') === value
          );
          break;
      }
    });
  
    setPartnerList(filteredData);
    updateTableSm(false);
  };

  const resetFilter = () => {
    setFilters({
      status: "",
      country: "",
      date: ""
    });
    setPartnerList(data || []);
    updateTableSm(false);
  };

  // Function to close form modal
  const onFormCancel = () => {
    setModal({ edit: false, add: false });
    resetForm();
  };

  // Submit function to add a new item
  const onFormSubmit = (submitData) => {
    setLoading(true);
  
    const submittedData = {
      organization_name: submitData.organization_name,
      contact_person_name: submitData.contact_person_name,
      contact_person_email: submitData.contact_person_email,
      contact_person_phone: submitData.contact_person_phone,
      contact_person_country_code: submitData.contact_person_country_code,
      contact_person_address: submitData.contact_person_address,
      contact_person_city: submitData.contact_person_city,
      contact_person_country: submitData.contact_person_country,
      client_manager: submitData.client_manager,
      business_website: submitData.business_website || "",
      business_registration: submitData.business_registration || "",
      organization_logo: submitData.organization_logo || "",
      isIncludeHotel: formData.isIncludeHotel === "yes",
      isIncludeAccommodation: formData.isIncludeAccommodation === "yes",
      isIncludeTranslator: formData.isIncludeTranslator === "yes",
      remarks: submitData.remarks || "",
      user_type: "medical_partner",
      status: formData.status || "pending"
    };
  
    console.log('Données à envoyer:', submittedData);
  
    axios
      .post(`${API_BASE_URL}/api/v1/contact-partner`, submittedData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        console.log('Réponse de création:', response);
        if (response.data.success) {
          // Recharger les données après l'ajout
          return axios.get(`${API_BASE_URL}/api/v1/contact-partner?user_type=medical_partner`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
        }
        throw new Error(response.data.message || 'Create failed');
      })
      .then(response => {
        if (response.data?.data) {
          setPartnerList(response.data.data);
          setData(response.data.data);
          setModal({ edit: false, add: false });
          resetForm();
          swal("Success", "Partner added successfully", "success");
        }
      })
      .catch(error => {
        console.error('Erreur de création:', error);
        swal("Error", error.response?.data?.message || "Failed to add partner", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
// Fonction de suppression
const onDeleteClick = async (id) => {
  try {
    const partnerToDelete = partnerList.find(item => item._id === id);
    if (!partnerToDelete) {
      swal("Error", "Partner not found", "error");
      return;
    }

    const willDelete = await swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this partner!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (willDelete) {
      setLoading(true);

      // Utiliser l'email non encodé
      const email = partnerToDelete.contact_person_email;
      const deleteUrl = `${API_BASE_URL}/api/v1/contact-partner/${email}`;
      
      console.log('Email à supprimer:', email);
      console.log('URL de suppression:', deleteUrl);

      const deleteResponse = await axios({
        method: 'DELETE',
        url: deleteUrl,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      console.log('Réponse complète de suppression:', deleteResponse);

      if (deleteResponse.data.success) {
        // Recharger les données après la suppression
        const refreshResponse = await axios.get(
          `${API_BASE_URL}/api/v1/contact-partner?user_type=medical_partner`,
          {
            headers: { 
              Authorization: `Bearer ${token}` 
            }
          }
        );

        if (refreshResponse.data?.data) {
          setPartnerList(refreshResponse.data.data);
          setData(refreshResponse.data.data);
        }

        swal("Success", "Partner deleted successfully", "success");
      } else {
        throw new Error(deleteResponse.data.message || 'Delete operation failed');
      }
    }
  } catch (error) {
    console.error('Erreur complète:', error);
    console.error('Détails de l\'erreur:', error.response?.data);
    const errorMessage = error.response?.data?.message || 
                        error.message || 
                        "Failed to delete partner";
    swal("Error", errorMessage, "error");
  } finally {
    setLoading(false);
  }
};

const onEditClick = (id) => {
  const item = partnerList.find(item => item._id === id);
  if (!item) return;

  setFormData({
    _id: item._id,
    organization_name: item.organization_name || "",
    contact_person_name: item.contact_person_name || "",
    contact_person_email: item.contact_person_email || "",
    contact_person_phone: item.contact_person_phone || "",
    contact_person_country_code: item.contact_person_country_code || "",
    contact_person_address: item.contact_person_address || "",
    contact_person_city: item.contact_person_city || "",
    contact_person_country: item.contact_person_country || "",
    client_manager: item.client_manager || "",
    business_website: item.business_website || "",
    business_registration: item.business_registration || "",
    organization_logo: item.organization_logo || "",
    default_logo: item.default_logo || "",
    isIncludeHotel: item.isIncludeHotel === true || item.isIncludeHotel === "yes" ? "yes" : "no",
    isIncludeAccommodation: item.isIncludeAccommodation === true || item.isIncludeAccommodation === "yes" ? "yes" : "no",
    isIncludeTranslator: item.isIncludeTranslator === true || item.isIncludeTranslator === "yes" ? "yes" : "no",
    remarks: item.remarks || "",
    user_type: "medical_partner",
    status: item.status || "",
    createdAt: item.createdAt || "",
  });

  setModal({ edit: true, add: false });
  setEditedId(item._id);
};

  // Submit function to update item
  const onEditSubmit = (submitData) => {
    setLoading(true);
    console.log('Submit Data reçues:', submitData); // Pour debug
  
    const originalItem = partnerList.find(item => item._id === editId);
    if (!originalItem) {
      setLoading(false);
      swal("Error", "Medical partner not found", "error");
      return;
    }
  
    // S'assurer que les valeurs du business sont incluses depuis submitData
    const submissionData = {
      ...submitData, // Inclure toutes les données du formulaire d'abord
      contact_person_email: originalItem.contact_person_email,
      isIncludeHotel: formData.isIncludeHotel === "yes",
      isIncludeAccommodation: formData.isIncludeAccommodation === "yes",
      isIncludeTranslator: formData.isIncludeTranslator === "yes",
      user_type: "medical_partner",
      // Préserver les valeurs existantes si non modifiées
      business_website: submitData.business_website || originalItem.business_website || "",
      business_registration: submitData.business_registration || originalItem.business_registration || "",
      status: formData.status
    };
  
    console.log('Données finales à envoyer:', submissionData);
  
    const updateUrl = `${API_BASE_URL}/api/v1/contact-partner/${originalItem.contact_person_email}`;
  
    axios({
      method: 'PUT',
      url: updateUrl,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data: submissionData
    })
    .then(response => {
      console.log('Réponse de mise à jour:', response);
      if (response.data.success) {
        return axios.get(`${API_BASE_URL}/api/v1/contact-partner?user_type=medical_partner`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
      }
      throw new Error(response.data.message || 'Update failed');
    })
    .then(response => {
      if (response.data?.data) {
        setPartnerList(response.data.data);
        setData(response.data.data);
        setModal({ edit: false });
        resetForm();
        swal("Success", "Partner updated successfully", "success");
      }
    })
    .catch(error => {
      console.error('Erreur de mise à jour:', error);
      swal("Error", error.response?.data?.message || "Update failed", "error");
    })
    .finally(() => {
      setLoading(false);
    });
  };


// Function to suspend user

const suspendUser = (id) => {
  const partnerToSuspend = partnerList.find(item => item._id === id);
  if (!partnerToSuspend) {
    swal("Error", "Partner not found", "error");
    return;
  }

  swal({
    title: "Are you sure?",
    text: "Do you want to suspend this partner?",
    icon: "warning",
    buttons: true,
    dangerMode: true,
  })
  .then((willSuspend) => {
    if (willSuspend) {
      setLoading(true);
      
      const submissionData = {
        ...partnerToSuspend,
        status: "Suspend"
      };

      axios
        .put(`${API_BASE_URL}/api/v1/contact-partner/${partnerToSuspend.contact_person_email}`, submissionData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        })
        .then(response => {
          if (response.data.success) {
            return axios.get(`${API_BASE_URL}/api/v1/contact-partner?user_type=medical_partner`, {
              headers: {
                Authorization: `Bearer ${token}`
              }
            });
          }
          throw new Error(response.data.message || 'Suspend failed');
        })
        .then(response => {
          if (response.data?.data) {
            setPartnerList(response.data.data);
            setData(response.data.data);
            swal("Success", "Partner suspended successfully", "success");
          }
        })
        .catch(error => {
          console.error('Error:', error);
          swal("Error", error.response?.data?.message || "Failed to suspend partner", "error");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  });
};

  // Function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  const { errors, register, handleSubmit } = useForm();

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = partnerList.filter(item => item && item.organization_name).slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <Head title="User List - Compact"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Medical Partner Lists
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {partnerList.length} users.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <DataTable className="card-stretch">
          <div className="card-inner position-relative card-tools-toggle">
  <div className="card-title-group">
    <div className="card-tools"></div>
    <div className="card-tools mr-n1">
      <ul className="btn-toolbar gx-1">
        <li>
          <a
            href="#search"
            onClick={(ev) => {
              ev.preventDefault();
              toggle();
            }}
            className="btn btn-icon search-toggle toggle-search"
          >
            <Icon name="search"></Icon>
          </a>
        </li>
        <li className="btn-toolbar-sep"></li>
        <button className="btn btn-primary" onClick={() => setModal({ add: true })}>
                      Add New Partner
                    </button>
        <li>
          <div className="toggle-wrap">
            <Button
              className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
              onClick={() => updateTableSm(true)}
            >
              <Icon name="menu-right"></Icon>
            </Button>
            <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
              <ul className="btn-toolbar gx-1">
                <li className="toggle-close">
                  <Button className="btn-icon btn-trigger toggle" onClick={() => updateTableSm(false)}>
                    <Icon name="arrow-left"></Icon>
                  </Button>
                </li>
                <li>
                  <UncontrolledDropdown>
                    <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                      <div className="dot dot-primary"></div>
                      <Icon name="filter-alt"></Icon>
                    </DropdownToggle>
                    <DropdownMenu
                      right
                      className="filter-wg dropdown-menu-xl"
                      style={{ overflow: "visible" }}
                    >
                      <div className="dropdown-head">
                        <span className="sub-title dropdown-title">Filter Partners</span>
                      </div>
                      <div className="dropdown-body dropdown-body-rg">
  <Row className="gx-6 gy-3">
    <Col size="6">
      <FormGroup>
        <label className="overline-title overline-title-alt">Status</label>
        <RSelect 
          options={statusOptions}
          value={statusOptions.find(option => option.value === filters.status)}
          onChange={(e) => onFilterChange(e, 'status')}
          placeholder="Select Status"
        />
      </FormGroup>
    </Col>
    <Col size="6">
      <FormGroup>
        <label className="overline-title overline-title-alt">Country</label>
        <RSelect 
          options={countryOptions}
          value={countryOptions.find(option => option.value === filters.country)}
          onChange={(e) => onFilterChange(e, 'country')}
          placeholder="Select Country"
        />
      </FormGroup>
    </Col>
    <Col size="6">
      <FormGroup>
        <label className="overline-title overline-title-alt">Created Date</label>
        <input 
          type="date" 
          className="form-control" 
          value={filters.date}
          onChange={(e) => onFilterChange(e, 'date')}
        />
      </FormGroup>
    </Col>
    <Col size="12">
      <div className="d-flex justify-content-between">
        <Button color="primary" onClick={applyFilter}>
          Apply Filter
        </Button>
        <Button color="secondary" onClick={resetFilter}>
          Reset Filter
        </Button>
      </div>
    </Col>
  </Row>
</div>
                      <div className="dropdown-foot between">
                        <a
                          href="#reset"
                          onClick={(ev) => {
                            ev.preventDefault();
                            resetFilter();
                          }}
                          className="clickable"
                        >
                          Reset Filter
                        </a>
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div className={`card-search search-wrap ${!onSearch && "active"}`}>
    <div className="card-body">
      <div className="search-content">
        <Button
          className="search-back btn-icon toggle-search active"
          onClick={() => {
            setSearchText("");
            toggle();
          }}
        >
          <Icon name="arrow-left"></Icon>
        </Button>
        <input
          type="text"
          className="border-transparent form-focus-none form-control"
          placeholder="Search by organization, name, email or phone"
          value={onSearchText}
          onChange={(e) => onFilterChange(e)}
        />
        <Button className="search-submit btn-icon">
          <Icon name="search"></Icon>
        </Button>
      </div>
    </div>
  </div>
</div>
            <DataTableBody compact>
  <DataTableHead>
    <DataTableRow size="lg">
      <span className="sub-text">Organization Name</span>
    </DataTableRow>
    <DataTableRow size="lg">
      <span className="sub-text">Manager</span>
    </DataTableRow>
    <DataTableRow size="lg">
      <span className="sub-text">Contact Info</span>
    </DataTableRow>
    <DataTableRow size="lg">
      <span className="sub-text">Address</span>
    </DataTableRow>
    <DataTableRow size="lg">
      <span className="sub-text">Remarks</span>
    </DataTableRow>
    <DataTableRow size="md">
      <span className="sub-text">Status</span>
    </DataTableRow>
    <DataTableRow size="md">
      <span className="sub-text">Date</span>
    </DataTableRow>
    <DataTableRow className="nk-tb-col-tools text-right"></DataTableRow>
  </DataTableHead>
  {/*Head*/}
  {currentItems.length > 0 ? currentItems.map((item) => {
  if (!item) return null;
  return (
    <DataTableItem key={item._id || Math.random()}>
      <DataTableRow size="lg">
        <span>{item.organization_name || '-'}</span>
      </DataTableRow>
      <DataTableRow size="lg">
        <span>{item.client_manager || '-'}</span>
      </DataTableRow>
      <DataTableRow size="lg">
        <div>
          <span>{item.contact_person_name || '-'}</span>
          <br />
          <span>{item.contact_person_email || '-'}</span>
          <br />
          <span>{(item.contact_person_country_code || '') + ' ' + (item.contact_person_phone || '-')}</span>
        </div>
      </DataTableRow>
      <DataTableRow size="lg">
        <div>
          <span>{item.contact_person_address || '-'}</span>
          <br />
          <span>{item.contact_person_city || '-'}</span>
          <br />
          <span>{item.contact_person_country || '-'}</span>
        </div>
      </DataTableRow>
      <DataTableRow size="lg">
        <span>{item.remarks || '-'}</span>
      </DataTableRow>
      <DataTableRow size="md">
        <span>{item.status || '-'}</span>
      </DataTableRow>
      <DataTableRow size="md">
        <span>{item.createdAt ? moment(item.createdAt).format("DD/MM/YY") : '-'}</span>
      </DataTableRow>

      <DataTableRow className="nk-tb-col-tools">
        <ul className="nk-tb-actions gx-1">
          <li className="nk-tb-action-hidden" onClick={() => onEditClick(item._id)}>
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-trigger btn-icon"
              id={`edit-${item._id}`}
              icon="edit-alt-fill"
              direction="top"
              text="Edit"
            />
          </li>
          {item.status !== "Suspend" && (
            <React.Fragment>
              <li className="nk-tb-action-hidden" onClick={() => suspendUser(item._id)}>
                <TooltipComponent
                  tag="a"
                  containerClassName="btn btn-trigger btn-icon"
                  id={`suspend-${item._id}`}
                  icon="user-cross-fill"
                  direction="top"
                  text="Suspend"
                />
              </li>
            </React.Fragment>
          )}
          <li>
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li onClick={() => onEditClick(item._id)}>
                    <DropdownItem
                      tag="a"
                      href="#edit"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <Icon name="edit"></Icon>
                      <span>Edit</span>
                    </DropdownItem>
                  </li>
                  <li onClick={() => onDeleteClick(item._id)}>
                                      <DropdownItem
                                        tag="a"
                                        href="#delete"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        <Icon name="delete"></Icon>
                                        <span>Delete</span>
                                      </DropdownItem>
                                    </li>
                  {item.status !== "Suspend" && (
                    <React.Fragment>
                      <li className="divider"></li>
                      <li onClick={() => suspendUser(item._id)}>
                        <DropdownItem
                          tag="a"
                          href="#suspend"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          <Icon name="na"></Icon>
                          <span>Suspend User</span>
                        </DropdownItem>
                      </li>
                    </React.Fragment>
                  )}
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </DataTableRow>
    </DataTableItem>
  );
}) : (
  <div className="text-center">
    <span className="text-silent">No data found</span>
  </div>
)}
</DataTableBody>
            <div className="card-inner">
              {currentItems.length > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={partnerList.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
          </DataTable>
        </Block>
{/* Add Modal */}
<Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
  <ModalBody>
    <a href="#cancel" onClick={(ev) => {ev.preventDefault(); onFormCancel();}} className="close">
      <Icon name="cross-sm"></Icon>
    </a>
    <div className="p-2">
      <h5 className="title">Add Medical Partner</h5>
      <div className="mt-4">
        <Form className="row gy-4" onSubmit={handleSubmit(onFormSubmit)}>
          {/* Organization Information */}
          <Col md="12">
            <h6 className="overline-title">Organization Details</h6>
          </Col>
          <Col md="6">
            <FormGroup>
              <label className="form-label">Organization Name</label>
              <input
                className="form-control"
                type="text"
                name="organization_name"
                defaultValue={formData.organization_name}
                placeholder="Enter organization name"
                ref={register({ required: "This field is required" })}
              />
              {errors.organization_name && <span className="invalid">{errors.organization_name.message}</span>}
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <label className="form-label">Client Manager</label>
              <input
                className="form-control"
                type="text"
                name="client_manager"
                defaultValue={formData.client_manager}
                placeholder="Enter client manager name"
                ref={register}
              />
              {errors.client_manager && <span className="invalid">{errors.client_manager.message}</span>}
            </FormGroup>
          </Col>

          {/* Contact Person Information */}
          <Col md="12">
            <h6 className="overline-title">Contact Person Details</h6>
          </Col>
          <Col md="6">
            <FormGroup>
              <label className="form-label">Contact Person Name</label>
              <input
                className="form-control"
                type="text"
                name="contact_person_name"
                defaultValue={formData.contact_person_name}
                placeholder="Enter contact person name"
                ref={register}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <label className="form-label">Contact Person Email</label>
              <input
                className="form-control"
                type="email"
                name="contact_person_email"
                defaultValue={formData.contact_person_email}
                placeholder="Enter email"
                ref={register({
                  required: "This field is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address"
                  }
                })}
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <label className="form-label">Country Code</label>
              <input
                className="form-control"
                type="text"
                name="contact_person_country_code"
                defaultValue={formData.contact_person_country_code}
                placeholder="+XX"
                ref={register}
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <label className="form-label">Phone Number</label>
              <input
                className="form-control"
                type="text"
                name="contact_person_phone"
                defaultValue={formData.contact_person_phone}
                placeholder="Enter phone number"
                ref={register}
              />
            </FormGroup>
          </Col>
          
          {/* Address Information */}
          <Col md="12">
            <h6 className="overline-title">Address Details</h6>
          </Col>
          <Col md="12">
            <FormGroup>
              <label className="form-label">Address</label>
              <input
                className="form-control"
                type="text"
                name="contact_person_address"
                defaultValue={formData.contact_person_address}
                placeholder="Enter address"
                ref={register}
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label className="form-label">City</label>
              <input
                className="form-control"
                type="text"
                name="contact_person_city"
                defaultValue={formData.contact_person_city}
                placeholder="Enter city"
                ref={register}
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label className="form-label">Country</label>
              <input
                className="form-control"
                type="text"
                name="contact_person_country"
                defaultValue={formData.contact_person_country}
                placeholder="Enter country"
                ref={register}
              />
            </FormGroup>
          </Col>

          {/* Business Information */}
          <Col md="12">
            <h6 className="overline-title">Business Details</h6>
          </Col>
          <Col md="6">
  <FormGroup>
    <label className="form-label">Business Website</label>
    <input
      className="form-control"
      type="text"
      name="business_website"
      defaultValue={formData.business_website}
      placeholder="Enter website URL"
      ref={register()} // Important !
    />
  </FormGroup>
</Col>
<Col md="6">
  <FormGroup>
    <label className="form-label">Business Registration</label>
    <input
      className="form-control"
      type="text"
      name="business_registration"
      defaultValue={formData.business_registration}
      placeholder="Enter registration number"
      ref={register()} // Important !
    />
  </FormGroup>
</Col>

          {/* Services */}
          <Col md="12">
            <h6 className="overline-title">Services</h6>
          </Col>
          <Col md="4">
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id="includeHotel"
                name="isIncludeHotel"
                checked={formData.isIncludeHotel === "yes"}
                onChange={(e) => setFormData({ ...formData, isIncludeHotel: e.target.checked ? "yes" : "no" })}
              />
              <label className="custom-control-label" htmlFor="includeHotel">Include Hotel</label>
            </div>
          </Col>
          <Col md="4">
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id="includeAccommodation"
                name="isIncludeAccommodation"
                checked={formData.isIncludeAccommodation === "yes"}
                onChange={(e) => setFormData({ ...formData, isIncludeAccommodation: e.target.checked ? "yes" : "no" })}
              />
              <label className="custom-control-label" htmlFor="includeAccommodation">Include Accommodation</label>
            </div>
          </Col>
          <Col md="4">
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id="includeTranslator"
                name="isIncludeTranslator"
                checked={formData.isIncludeTranslator === "yes"}
                onChange={(e) => setFormData({ ...formData, isIncludeTranslator: e.target.checked ? "yes" : "no" })}
              />
              <label className="custom-control-label" htmlFor="includeTranslator">Include Translator</label>
            </div>
          </Col>

          {/* Other Information */}
          <Col md="12">
            <h6 className="overline-title">Additional Information</h6>
          </Col>
          <Col md="12">
            <FormGroup>
              <label className="form-label">Remarks</label>
              <textarea
                className="form-control"
                name="remarks"
                defaultValue={formData.remarks}
                placeholder="Enter remarks"
                rows="3"
                ref={register()} // Important !
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <label className="form-label">Status</label>
              <RSelect
                options={[
                  { value: 'Active', label: 'Active' },
                  { value: 'Pending', label: 'Pending' },
                  { value: 'Suspend', label: 'Suspended' }
                ]}
                name="status"
                defaultValue={formData.status}
                onChange={(e) => setFormData({ ...formData, status: e.value })}
              />
            </FormGroup>
          </Col>

          <Col size="12">
            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
              <li>
                <Button color="primary" size="md" type="submit">
                  {modal.edit ? "Update Partner" : "Add Partner"}
                </Button>
              </li>
              <li>
                <a
                  href="#cancel"
                  onClick={(ev) => {
                    ev.preventDefault();
                    onFormCancel();
                  }}
                  className="link link-light"
                >
                  Cancel
                </a>
              </li>
            </ul>
          </Col>
        </Form>
      </div>
    </div>
  </ModalBody>
</Modal>
<Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
<ModalBody>
    <a href="#cancel" onClick={(ev) => {ev.preventDefault(); onFormCancel();}} className="close">
      <Icon name="cross-sm"></Icon>
    </a>
    <div className="p-2">
      <h5 className="title">Edit Medical Partner</h5>
      <div className="mt-4">
        <Form className="row gy-4" onSubmit={handleSubmit(onEditSubmit)}>
          {/* Organization Information */}
          <Col md="12">
            <h6 className="overline-title">Organization Details</h6>
          </Col>
          <Col md="6">
            <FormGroup>
              <label className="form-label">Organization Name</label>
              <input
                className="form-control"
                type="text"
                name="organization_name"
                defaultValue={formData.organization_name}
                placeholder="Enter organization name"
                ref={register}
              />
              {errors.organization_name && <span className="invalid">{errors.organization_name.message}</span>}
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <label className="form-label">Client Manager</label>
              <input
                className="form-control"
                type="text"
                name="client_manager"
                defaultValue={formData.client_manager}
                placeholder="Enter client manager name"
                ref={register}
              />
              {errors.client_manager && <span className="invalid">{errors.client_manager.message}</span>}
            </FormGroup>
          </Col>

          {/* Contact Person Information */}
          <Col md="12">
            <h6 className="overline-title">Contact Person Details</h6>
          </Col>
          <Col md="6">
            <FormGroup>
              <label className="form-label">Contact Person Name</label>
              <input
                className="form-control"
                type="text"
                name="contact_person_name"
                defaultValue={formData.contact_person_name}
                placeholder="Enter contact person name"
                ref={register}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <label className="form-label">Contact Person Email</label>
              <input
                className="form-control"
                type="email"
                name="contact_person_email"
                defaultValue={formData.contact_person_email}
                placeholder="Enter email"
                ref={register({
                  required: "This field is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address"
                  }
                })}
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <label className="form-label">Country Code</label>
              <input
                className="form-control"
                type="text"
                name="contact_person_country_code"
                defaultValue={formData.contact_person_country_code}
                placeholder="+XX"
                ref={register}
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <label className="form-label">Phone Number</label>
              <input
                className="form-control"
                type="text"
                name="contact_person_phone"
                defaultValue={formData.contact_person_phone}
                placeholder="Enter phone number"
                ref={register}
              />
            </FormGroup>
          </Col>
          
          {/* Address Information */}
          <Col md="12">
            <h6 className="overline-title">Address Details</h6>
          </Col>
          <Col md="12">
            <FormGroup>
              <label className="form-label">Address</label>
              <input
                className="form-control"
                type="text"
                name="contact_person_address"
                defaultValue={formData.contact_person_address}
                placeholder="Enter address"
                ref={register}
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label className="form-label">City</label>
              <input
                className="form-control"
                type="text"
                name="contact_person_city"
                defaultValue={formData.contact_person_city}
                placeholder="Enter city"
                ref={register}
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label className="form-label">Country</label>
              <input
                className="form-control"
                type="text"
                name="contact_person_country"
                defaultValue={formData.contact_person_country}
                placeholder="Enter country"
                ref={register}
              />
            </FormGroup>
          </Col>

          {/* Business Information */}
          <Col md="12">
            <h6 className="overline-title">Business Details</h6>
          </Col>
          <Col md="6">
  <FormGroup>
    <label className="form-label">Business Website</label>
    <input
      className="form-control"
      type="text"
      name="business_website"
      defaultValue={formData.business_website}
      placeholder="Enter website URL"
      ref={register()} // Important !
    />
  </FormGroup>
</Col>
<Col md="6">
  <FormGroup>
    <label className="form-label">Business Registration</label>
    <input
      className="form-control"
      type="text"
      name="business_registration"
      defaultValue={formData.business_registration}
      placeholder="Enter registration number"
      ref={register()} // Important !
    />
  </FormGroup>
</Col>

          {/* Services */}
          <Col md="12">
            <h6 className="overline-title">Services</h6>
          </Col>
          <Col md="4">
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id="includeHotel"
                name="isIncludeHotel"
                checked={formData.isIncludeHotel === "yes"}
                onChange={(e) => setFormData({ ...formData, isIncludeHotel: e.target.checked ? "yes" : "no" })}
              />
              <label className="custom-control-label" htmlFor="includeHotel">Include Hotel</label>
            </div>
          </Col>
          <Col md="4">
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id="includeAccommodation"
                name="isIncludeAccommodation"
                checked={formData.isIncludeAccommodation === "yes"}
                onChange={(e) => setFormData({ ...formData, isIncludeAccommodation: e.target.checked ? "yes" : "no" })}
              />
              <label className="custom-control-label" htmlFor="includeAccommodation">Include Accommodation</label>
            </div>
          </Col>
          <Col md="4">
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id="includeTranslator"
                name="isIncludeTranslator"
                checked={formData.isIncludeTranslator === "yes"}
                onChange={(e) => setFormData({ ...formData, isIncludeTranslator: e.target.checked ? "yes" : "no" })}
              />
              <label className="custom-control-label" htmlFor="includeTranslator">Include Translator</label>
            </div>
          </Col>

          {/* Other Information */}
          <Col md="12">
            <h6 className="overline-title">Additional Information</h6>
          </Col>
          <Col md="12">
            <FormGroup>
              <label className="form-label">Remarks</label>
              <textarea
                className="form-control"
                name="remarks"
                defaultValue={formData.remarks}
                placeholder="Enter remarks"
                rows="3"
                ref={register()} // Important !

              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <label className="form-label">Status</label>
              <RSelect
                options={[
                  { value: 'Active', label: 'Active' },
                  { value: 'Pending', label: 'Pending' },
                  { value: 'Suspend', label: 'Suspended' }
                ]}
                name="status"
                defaultValue={formData.status}
                onChange={(e) => setFormData({ ...formData, status: e.value })}
              />
            </FormGroup>
          </Col>

          <Col size="12">
            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
              <li>
                <Button color="primary" size="md" type="submit">
                  {modal.edit ? "Update Partner" : "Add Partner"}
                </Button>
              </li>
              <li>
                <a
                  href="#cancel"
                  onClick={(ev) => {
                    ev.preventDefault();
                    onFormCancel();
                  }}
                  className="link link-light"
                >
                  Cancel
                </a>
              </li>
            </ul>
          </Col>
        </Form>
      </div>
    </div>
  </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};
export default MedicalPartner;
